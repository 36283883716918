// basic react component with named earningpage and a button
import React, { useContext, useEffect, useRef, useMemo } from "react";
import "./index.scss";
import axios from "axios";

// API
import { getApiUrl } from "../../api/api-config";

// Assets
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import { CloseIcon } from "../../assets/icons/close-icon";
import { earningConstants } from "../../assets/constants/constants-text";

// Components
import EarningsInsightCuration from "../../components/earnings-insight-curation";
import EarningTable from "../../components/earning-table";
import Card from "../../components/card";
import EarningsBarGraph from "../../components/graph/earnings-bar-graph";
import EarningsNotes from "../../components/earnings-notes";
import CourselCard from "../../components/coursel-card";
import CustomCarousel from "../../components/custom-carousel";
import { Loader } from "@gitlab-rtsensing/component-library";
import ExternalModal from "../../components/external-custom-modal";
import EarningsPopupModal from "../../components/earnings-notable-popup-modal";
import { Button } from "@gitlab-rtsensing/component-library";
import EarningsWordCloud from "../../components/earnings-word-cloud";
import { TimeScaleData } from "../../api/get-year-and-quarter-list-dt";

// Utils
import { apiResponse, getOktaToken } from "../../utils/commonMethods";
import { AppContext } from "../../utils/app-context";
import EarningsDropdown from "../../components/earnings-dropdown";
import { useLocation } from "react-router-dom";
import dayjs from "../../utils/dayjs";


type EarningPageProps = {
  label?: string;
  timeScaleData: TimeScaleData;
};

const EarningPage: React.FC<EarningPageProps> = (props: EarningPageProps) => {
  const { timeScaleData } = props;
  const [tableLoading, setTableLoading] = React.useState(false);
  const [tableError, setTableError] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [earningsTableData, setEarningsTableData] = React.useState(undefined);
  const [tableHeader, setTableHeader] = React.useState({
    quarter: "",
    year: "",
    price_date: "",
  });
  const [toggleState, setToggleState] = React.useState(false);
  const [notesList, setNoteList] = React.useState(undefined);
  const [keyInsightsList, setKeyInsightsList] = React.useState([]);
  const [keyInsightsListError, setKeyInsightsListError] = React.useState("");
  const [keyInsightsListLoading, setKeyInsightsListLoading] =
    React.useState(false);
  const [keyInsightsSummary, setKeyInsightsSummary] = React.useState([]);
  const [keyInsightsSummaryError, setKeyInsightsSummaryError] =
    React.useState("");
  const [keyInsightsSummaryLoading, setKeyInsightsSummaryLoading] =
    React.useState(false);
  const [earningsGraphDropdownLoading, setEarningsGraphDropdownLoading] =
    React.useState(false);
  const [earningsGraphDropdown, setEarningsGraphDropdown] = React.useState([]);

  // Earnings Financial Summary Year/Quarter Values
  const [summaryYear, setSummaryYear] = React.useState<string>(Object.keys(timeScaleData)[1]);
  const [summaryQuarter, setSummaryQuarter] = React.useState<string>(timeScaleData[summaryYear][0]);

  // Earnings Comparative Analysis Year/Quarter Values
  const [analysisYear, setAnalysisYear] = React.useState<string>(Object.keys(timeScaleData)[1]);
  const [analysisQuarter, setAnalysisQuarter] = React.useState<string>(timeScaleData[analysisYear][0]);

  const [earningsGraphDropdownError, setEarningsGraphDropdownError] =
    React.useState("");
  const [earningsGraphLoading, setEarningsGraphLoading] = React.useState(false);

  {/* Commented out until 2021 data is curated - ADIPRTSANA-2397 */}
  const [earningsGraphDataPrevYear, setEarningsGraphDataPrevYear] =
    React.useState([]);
  const [earningsGraphDataCurrYear, setEarningsGraphDataCurrYear] =
    React.useState([]);

  const [earningsGraphDataError, setEarningsGraphDataError] =
    React.useState("");

  const [amgenRankingValue, setAmgenRankingValue] =
    React.useState<string>("R&D % of Sales");
  const [isEarningSelected, setIsEarningSelected] = React.useState(true);

  const [toggleValue, setToggleValue] = React.useState("earning");
  const [popupModalHeaderData, setPopupModalHeaderData]: any = React.useState(
    {}
  );
  const [openPopUpModal, setOnOpenPopUpModal] = React.useState(false);
  const [companyPageUrl, setCompanyPageUrl] = React.useState("");
  const [presignedUrl, setPresignedUrl] = React.useState("");

  const oktaToken = getOktaToken();
  const { authData } = useContext(AppContext);

  const earningsSentimentRef = useRef<HTMLDivElement>(null);
  const investorRelationsInsightsRef = useRef<HTMLDivElement>(null);
  const financialSummaryRef = useRef<HTMLDivElement>(null);
  const comparativeAnalysisRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const removeFullYear = (data: TimeScaleData = timeScaleData): TimeScaleData => {
    let filteredData: TimeScaleData = {};
    for (let year in data) {
      filteredData[year] = data[year].filter((quarter) => quarter !== "Full Year");
    }
    return filteredData;
  };

  const timeScaleDataMinusFullYear = removeFullYear(timeScaleData);

  // Investor Relations Insights Year/Quarter Values
  const [investorRelationsYear, setInvestorRelationsYear] = React.useState<string>(
    Object.keys(timeScaleDataMinusFullYear)[1]
  );
  const [investorRelationsQuarter, setInvestorRelationsQuarter] = React.useState<string>(
    timeScaleDataMinusFullYear[investorRelationsYear][0]
  );

    
  const checkIfEarningSeason = keyInsightsList.some(item => {
    const earningsCallDate = item['earnings_call_date'];
    // Check if the earnings_call_date is valid (not null, not empty, and a valid date)
    if (earningsCallDate) {
        //  earnings season ends at 12 PM PST of the last earnings call date
        return dayjs()?.isSameOrBefore(earningsCallDate, 'day');
    }  else {
        return true;  // If earnings_call_date is invalid, treat it as in earnings season
    }
});

  const sortedKeyInsightsList = useMemo(() => {
  
    if (!checkIfEarningSeason) return keyInsightsList;
    const today = dayjs();
    return keyInsightsList.sort((a: any, b: any) => {
        const dateA = dayjs(a.earnings_call_date);
        const dateB = dayjs(b.earnings_call_date);

        // Handle null dates (null dates should go to the end)
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;

        // Handle future dates (future dates should go after past dates)
        const isFutureA = dateA.isAfter(today, 'day');
        const isFutureB = dateB.isAfter(today, 'day');

        if (isFutureA && isFutureB) {
          if (dateA.isSame(dateB, 'day')) return 0
          return dateA.isAfter(dateB, 'day') ? 1 : -1;
        }
        if (isFutureA) return 1;  // Future dates should go after past dates
        if (isFutureB) return -1; // Past dates should go before future dates

        // Handle past dates (past dates sorted by proximity to today)
        if (dateA.isSame(dateB, 'day')) return 0
        return dateA.isAfter(dateB, 'day') ? -1 : 1;
    });
  },[keyInsightsList])



const index = sortedKeyInsightsList.findIndex(item => dayjs().isSameOrBefore(item['earnings_call_date']));

const keyInsightsListCoursalData = index!=-1?[
  ...sortedKeyInsightsList.slice(0, index),  // Elements before the index
  {company_short_name:"", message:"Upcoming Earnings Reports"},                        // The new element to insert
  ...sortedKeyInsightsList.slice(index)      // Elements after the index
]:sortedKeyInsightsList;



  useEffect(() => {
    if (location && !authData.isLoading) {
      const currentEarningsSentiment: HTMLElement =
      earningsSentimentRef.current as unknown as HTMLElement;
      const currentInvestorRelations: HTMLElement =
      investorRelationsInsightsRef.current as unknown as HTMLElement;
      const currentFinancialSummary: HTMLElement =
      financialSummaryRef.current as unknown as HTMLElement;
      const currentComparativeAnalysis: HTMLElement =
      comparativeAnalysisRef.current as unknown as HTMLElement;
      
      if (location.hash === '#earningsSentiment') {
        if (currentEarningsSentiment) {
          setTimeout(() => {
            window.scroll({
              top:
              currentEarningsSentiment?.getBoundingClientRect().top +
                window.pageYOffset -
                60,
              behavior: 'smooth',
            });
          }, 2000);
        }
      }
      if (location.hash === '#investorRelationsInsights') {
        if (currentInvestorRelations) {
          setTimeout(() => {
            window.scroll({
              top:
                currentInvestorRelations.getBoundingClientRect().top +
                window.pageYOffset -
                70,
            });
          }, 2000);
        }
      }
      if (location.hash === '#financialSummary') {
        if (currentFinancialSummary) {
          setTimeout(() => {
            window.scroll({
              top:
                currentFinancialSummary.getBoundingClientRect().top +
                window.pageYOffset -
                70,
              behavior: 'smooth',
            });
          }, 2000);
        }
      }
      if (location.hash === '#comparativeAnalysis') {
        if (currentComparativeAnalysis) {
          setTimeout(() => {
            window.scroll({
              top:
                currentComparativeAnalysis.getBoundingClientRect().top +
                window.pageYOffset -
                70,
              behavior: 'smooth',
            });
          }, 2000);
        }
      }
     
    }
  }, [location, authData.isLoading]);

  const getTableData = async (quarter: string = summaryQuarter, year: string = summaryYear) => {
    setTableLoading(true);
    try {
      const res = await axios({
        method: "get",
        url: `${getApiUrl(
          "get-earning-table-data"
        )}?year=${year}&quarter=${quarter}`,
        data: {},
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res) {
        if (res && res.data && res.data.data) {
          const firstElement = res.data.data[0];
          res.data.data.splice(0, 1);

          res.data.data.map((ele: any) => {
            ele["notesSuperSet"] = [];
          });
          const quarter_label = firstElement.quarter === "Full Year" ? "FY" : `Q${firstElement.quarter}`
          setTableHeader({...firstElement, quarter: quarter_label});
          setTableData(res.data.data);
        }
      } else {
        setTableError(noDataMessage);
      }
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
      setTableError(apiErrorMessage);
    }
  };

  const getEarningsComparativeDropdown = async () => {
    setEarningsGraphDropdownLoading(true);
    try {
      const res = await apiResponse(
        "get",
        "get-earnings-comparison-dropdown",
        [],
        {}
      );
      if (res) {
        if (res && res.data && res.data.data) {
          setEarningsGraphDropdown(res.data.data);
        }
      } else {
        setEarningsGraphDropdownError(noDataMessage);
      }
      setEarningsGraphDropdownLoading(false);
    } catch (error) {
      setEarningsGraphDropdownLoading(false);
      setEarningsGraphDropdownError(apiErrorMessage);
    }
  };

  const quarterDetailsCurrYear = {
    year: Number(analysisYear),
    quarter: Number(analysisQuarter.substring(1))
  };

  const getEarningsComparativeAnalysisData = async (
    value: string,
    isPreviousYear: boolean = false,
  ) => {
    setEarningsGraphLoading(true);
    let year = Number(analysisYear)
    if(isPreviousYear){
      year = year-1
    }
    const updatedValue = value.replaceAll("&", "%26");
    try {
      const res = await axios({
        method: "get",
        url: `${getApiUrl(
          "get-earnings-comparison-values"
        )}?metric=${updatedValue}&year=${year}&quarter=${analysisQuarter}`,
        data: {},
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });

      if (res) {
        if (res && res.data && res.data.data) {
          isPreviousYear
            ? setEarningsGraphDataPrevYear(res.data.data)
            : setEarningsGraphDataCurrYear(res.data.data);
          setEarningsGraphDataError("");
        }
      } else {
        setEarningsGraphDataError(noDataMessage);
        setEarningsGraphDataCurrYear([]);
        setEarningsGraphDataPrevYear([]);
      }
      setEarningsGraphLoading(false);
    } catch (error) {
      setEarningsGraphLoading(false);
      setEarningsGraphDataError(apiErrorMessage);
      setEarningsGraphDataCurrYear([]);
      setEarningsGraphDataPrevYear([]);
    }
  };

  const getNotesList = async (quarter: string = summaryQuarter, year: string = summaryYear) => {
    try {
      const res = await axios({
        method: "get",
        url: `${getApiUrl(
          "get-user-notes"
        )}?note_category=Financial Summary Notes&quarter=${quarter === "Full Year" ? "4" : quarter[1]}&year=${year}`,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res && res.data && res.data.status === "SUCCESS") {
        setNoteList(res.data.data);
      } else {
        setNoteList(undefined);
      }
    } catch (error) {
      setNoteList(undefined);
    }
  };

  const getKeyInsightsData = async (quarter: string = investorRelationsQuarter, year: string = investorRelationsYear) => {
    setKeyInsightsListLoading(true);
    try {
      const res = await axios({
        method: "get",
        url: `${getApiUrl("get-key-insights")}?quarter=${quarter[1]}&year=${year}`,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res && res.data && res.data.status === "SUCCESS") {
        

        setKeyInsightsList(res.data.data);
      } else {
        setKeyInsightsListError(noDataMessage);
        setKeyInsightsList([]);
      }
      setKeyInsightsListLoading(false);
    } catch (error) {
      setKeyInsightsListLoading(false);
      setKeyInsightsListError(apiErrorMessage);
      setKeyInsightsList([]);
    }
  };

  const getKeyInsightsSummaryData = async (company_ticker: string) => {
    setKeyInsightsSummaryLoading(true);
    setKeyInsightsSummaryError("");
    try {
      const res = await axios({
        method: "get",
        url: getApiUrl("get-breakdown-summary"),
        params: {
          company_ticker: company_ticker,
          quarter: parseInt(investorRelationsQuarter[1]),
          year: parseInt(investorRelationsYear),
        },
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res && res.data && res.data.status === "SUCCESS") {
        setPresignedUrl(res?.data?.["presigned_url"]);
        setKeyInsightsSummary(res.data.data);
      } else {
        setKeyInsightsSummaryError(noDataMessage);
        setKeyInsightsSummary([]);
      }
      setKeyInsightsSummaryLoading(false);
    } catch (error) {
      setKeyInsightsSummaryLoading(false);
      setKeyInsightsSummaryError(apiErrorMessage);
      setKeyInsightsSummary([]);
    }
  };

  const resetToggle = () => {
    setToggleState(!toggleState);
  };

  const getEarningsFinancialSummaryData = (
    notesData: any,
    tableDataList: any
  ) => {
      notesData.map((note: any, mainIndex: number) => {
        const inputString = note && note.company_tag;
        inputString &&
          inputString.map((companyTickerValue: string, index: number) => {
            tableDataList &&
              tableDataList.map((tableDetails: any, index: number) => {
                return (
                  companyTickerValue === tableDetails.company_ticker &&
                  tableDetails.notesSuperSet.push({
                    mainIndex: mainIndex + 1,
                    noteContent: note.note_details,
                  })
                );
              });
          });
      });
      setEarningsTableData(tableDataList)
  };

  useEffect(() => {
    getKeyInsightsData();
    getTableData();
    getNotesList();
  }, [isEarningSelected]);

  useEffect(() => {
    getEarningsComparativeDropdown();
  }, []);

  useEffect(() => {
    if (notesList && tableData && tableData.length >= 1) {
      getEarningsFinancialSummaryData(notesList, tableData);
    }
  }, [tableData, notesList]);

  useEffect(() => {
    getEarningsComparativeAnalysisData(
      amgenRankingValue,
    );
     getEarningsComparativeAnalysisData(
      amgenRankingValue,
      true
    );
  }, [amgenRankingValue, analysisQuarter, analysisYear]);

  const onClosePopUp = () => {
    setOnOpenPopUpModal(false);
  };

  const onViewMoreButtonClick = (
    openPopUpModal: boolean,
    toggleState: string,
    company_ticker: string
  ) => {
    getKeyInsightsSummaryData(company_ticker);
    setOnOpenPopUpModal(openPopUpModal);
    setToggleValue(toggleState);
  };

  const checkSingleComparison = () => {
    if(amgenRankingValue === "YTD Price Growth %"){
      return true
    }
    return false
  }
  const singleComparison = checkSingleComparison();

  return (
    <>
      {authData?.externalAuthorization?.insightsCuration && (
        <div className="ext-earning-container ext-earning-insight-container ops-mt-4">
          <Button
            type="secondary"
            label="VIEW"
            className={`ext-earning-btn ${
              isEarningSelected ? "btn-selected" : "btn-unselected"
            }`}
            onClick={() => setIsEarningSelected(true)}
          ></Button>
          <Button
            type="secondary"
            label="EDIT"
            className={`ext-insight-btn ${
              !isEarningSelected ? "btn-selected" : "btn-unselected"
            }`}
            onClick={() => setIsEarningSelected(false)}
          ></Button>
        </div>
      )}
      {isEarningSelected ? (
        <>
          {authData?.externalAuthorization?.wordCloud && <div className="ext-earning-container ext-earnings-insights-container" id="earningsSentiment" ref={earningsSentimentRef}>
            <h3>
              {earningConstants.earningsSentiment}
            </h3>
            <div className="ext-row ops-mt-6" id="custom-carousel-wrapper">
                  {""}
                  {timeScaleData && (
                    <div className="ext-col-lg-8 ext-col-md-12">
                      <EarningsWordCloud timeScaleData={timeScaleDataMinusFullYear} />
                    </div>
                  )}
            </div>
          </div>}
          <div className="ext-earning-container ext-earnings-insights-container" id="investorRelationsInsights" ref={investorRelationsInsightsRef}>
            <h3 className=" ops-mt-10 ops-mb-5">
              {earningConstants.notableEarningInsides}
            </h3>
            <div className="ext-row" id="custom-carousel-wrapper">
              {keyInsightsListError ? (
                <div className="ext-earning-table-error">
                  {keyInsightsListError}
                </div>
              ) : keyInsightsListLoading ? (
                <div className="ext-earning-table-loader">
                  <Loader />
                </div>
              ) : (
                <>
                  {" "}
                  <CustomCarousel 
                    totalItems={keyInsightsListCoursalData.length}
                    timeScaleDataMinusFullYear={timeScaleDataMinusFullYear}
                    investorRelationsYear={investorRelationsYear}
                    investorRelationsQuarter={investorRelationsQuarter}
                    getKeyInsightsData={getKeyInsightsData}
                    setInvestorRelationsYear={setInvestorRelationsYear}
                    setInvestorRelationsQuarter={setInvestorRelationsQuarter}
                  >
                    {keyInsightsListCoursalData &&
                       keyInsightsListCoursalData.length > 0 && 
                       keyInsightsListCoursalData.map((item: any, index: number) => {

                        return (
                          <CourselCard
                            key={index}
                            cardData={item}
                            onViewMoreBtnClick={(
                              openPopUpModal: boolean,
                              toggleState: string
                            ) =>
                              onViewMoreButtonClick(
                                openPopUpModal,
                                toggleState,
                                item?.company_ticker
                              )
                            }
                            setPopupModalHeaderData={setPopupModalHeaderData}
                            setCompanyPageUrl={setCompanyPageUrl}
                          />
                        );
                      })}
                  </CustomCarousel>
                </>
              )}
            </div>
          </div>
          <div className="ext-earning-container"  id="financialSummary" ref={financialSummaryRef} >
            <h3 className="ops-mt-10 ops-mb-5">
              {earningConstants.earningFinancialSummary}
            </h3>
            <div className="ext-earnings-graph-container">
              <div
                className="ext-earnings-comparison-container ext-d-flex"
              >
                <EarningsDropdown
                  label="YEAR"
                  options={Object.keys(timeScaleData).reverse()}
                  selectedValue={summaryYear}
                  onMenuItemClick={(event)=> {
                    setSummaryYear(event.key)
                    setSummaryQuarter(timeScaleData[event?.key][0])
                    getTableData(timeScaleData[event?.key][0], event?.key);
                    getNotesList(timeScaleData[event?.key][0], event?.key)
                  }}

                />
                <EarningsDropdown
                  label="TIMEFRAME"
                  options={timeScaleData[summaryYear]}
                  selectedValue={summaryQuarter}
                  onMenuItemClick={ (event)=> {
                    setSummaryQuarter(event?.key)
                    getTableData(event?.key, summaryYear);
                    getNotesList(event?.key, summaryYear)
                  }}
                />
              </div>
            </div>
            <div className="ops-mt-4">
              <Card
                cardHeader={`Earnings: ${tableHeader && tableHeader.quarter} ${
                  tableHeader && tableHeader?.year
                }`}
                showToggle={true}
                toggleStatus={toggleState}
                setToggleStatus={() => resetToggle()}
                showNoSpacingOnHeader={true}
              > 
                { earningsTableData ? 
                  <EarningTable
                    isLoading={tableLoading}
                    tableHeaders={tableHeader}
                    isExpandTable={toggleState}
                    isError={tableError}
                    data={earningsTableData}
                    timeScaleData={timeScaleData}
                    getTableData={getTableData}
                    keysForComparisonData={{amgenRankingValue}}
                    getEarningsComparativeAnalysisData={getEarningsComparativeAnalysisData}
                    tableDataQuarter={summaryQuarter}
                    tableDataYear={summaryYear}
                  /> : <Loader />
                }
                <EarningsNotes
                  quarter={`${summaryQuarter === "Full Year" ? "4" : summaryQuarter[1]}`}
                  year={summaryYear}
                />
              </Card>
            </div>
          </div>
          <div className="ext-earning-container" id="comparativeAnalysis" ref={comparativeAnalysisRef}>
            <h3 className="ops-mt-10 ops-mb-5">{earningConstants.earningsComparativeAnalysis}</h3>
            <div className="ops-mt-4">
              <Card cardHeader="Amgen Ranking">
                <div className="ext-earnings-graph-container">
                  <div className="ext-earnings-comparison-summary-container">
                      <div className="ext-earnings-comparison-dropdown">
                        {earningsGraphDropdownError ? ( 
                          <div className="ext-earning-table-error">
                            {earningsGraphDropdownError}
                          </div>
                        ) : (
                          <>
                            {earningsGraphDropdownLoading ? (
                              <div className="ext-earning-table-loader">
                                <Loader />
                              </div>
                            ) : (
                              <div className="ext-earnings-comparison-summary-dropdown-container">
                                <EarningsDropdown
                                  label="COMPARISON"
                                  options={earningsGraphDropdown.map((item:any) => item.value)}
                                  selectedValue={amgenRankingValue}
                                  onMenuItemClick={(event)=> { setAmgenRankingValue(event.key)}}
                                />
                                {!singleComparison && 
                                  <>
                                    <EarningsDropdown
                                    label="YEAR"
                                    options={Object.keys(timeScaleData).reverse()}
                                    selectedValue={analysisYear}
                                    onMenuItemClick={(event)=> {
                                      setAnalysisYear(event.key)
                                      setAnalysisQuarter(timeScaleData[event.key][0])
                                    }}
                                    />
                                    <EarningsDropdown
                                      label="TIMEFRAME"
                                      options={timeScaleData[analysisYear]}
                                      selectedValue={analysisQuarter}
                                      onMenuItemClick={(event)=> { setAnalysisQuarter(event?.key)}}
                                    />
                                  </>
                                }
                              </div>
                            )}
                          </>
                        )}
                      </div>
                  </div>

                  {/* Current Selected Year */}
                  <div className="ext-earnings-graph-display-container">
                    <EarningsBarGraph
                      isError={earningsGraphDataError}
                      isLoading={earningsGraphLoading}
                      earningsGraphData={earningsGraphDataCurrYear}
                      noLabel={singleComparison} // If single comparison, don't show ANY header. Leaving the option open in future.
                      amgenRankingValue={amgenRankingValue}
                      selectedYear={quarterDetailsCurrYear?.year}
                      selectedQuarter={analysisQuarter}
                      isToolTip={false}
                    />
                    {/* Current Selected Year - 1 */}
                    {!singleComparison && <EarningsBarGraph
                      isError={earningsGraphDataError}
                      isLoading={earningsGraphLoading}
                      earningsGraphData={earningsGraphDataPrevYear}
                      amgenRankingValue={amgenRankingValue}
                      selectedYear={quarterDetailsCurrYear?.year -1}
                      selectedQuarter={analysisQuarter}
                      isToolTip={true}
                    />}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </>
      ) : (
        <div className="ext-earning-container-insight ops-mt-4">
          <EarningsInsightCuration 
            timeScaleData={removeFullYear(timeScaleData)}
          />
        </div>
      )}

      {/* Pop Up Modal */}
      {openPopUpModal && (
        <div>
          <ExternalModal
            title={
              <div
                className="ext-earning-table-company-logo ext-d-flex"
                style={{ alignItems: "center" }}
              >
                <img
                  src={popupModalHeaderData?.["imageUrl"]}
                  className="ext-table-company-icon"
                />
                <span
                  style={{
                    paddingLeft: "10px",
                    color: "#2F2F2F",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >{`${popupModalHeaderData?.companyName} Earnings Q${popupModalHeaderData?.quarter} ${popupModalHeaderData?.year}`}</span>
              </div>
            }
            visible={openPopUpModal}
            closeEvent={() => onClosePopUp()}
            icon={<CloseIcon height={20} width={20} className="close-icon" />}
            className="px-4 py-3 ext-earnings-transcripts-modal-container"
            childClassName="ext-earnings-modal"
            transcriptsModalWidthSizedClass="ext-transcripts-modal-width-sized"
          >
            <div className="ext-earnings-modal-container ops-p-3">
              <div style={{fontSize: "14px", fontWeight:"700"}} className="ext-earnings-label-container ext-d-flex">
                <span>
                 <strong> Earnings Transcript Summary</strong>
                </span>
              </div>
              {keyInsightsSummaryError ? (
                <div>{keyInsightsSummaryError}</div>
              ) : keyInsightsSummaryLoading ? (
                <div style={{height: "300px"}}><Loader /></div>
              ) : (
                !keyInsightsSummaryLoading &&
                keyInsightsSummary &&
                keyInsightsSummary?.length > 0 ? (
                  <EarningsPopupModal
                    summaryData={keyInsightsSummary}
                    toggleValue={toggleValue}
                    companyPageUrl={companyPageUrl}
                    presignedUrl={presignedUrl}
                  />
                )
                :
                <p className="ops-py-5 ops-fs-12 ext-earnings-popup-no-data-content">
                  {`No Breakdown Summary available for Q${popupModalHeaderData?.quarter} ${popupModalHeaderData?.year}`}
                </p>
              )}
            </div>
          </ExternalModal>
        </div>
      )}
      <div style={{margin: '20px 0px'}} />
    </>
  );
};

export default EarningPage;
