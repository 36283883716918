import { useState, useEffect, useRef, useMemo } from "react";
import { WordCloud, Card, Tabs } from "@opsdti-global-component-library/amgen-design-system"
import { lastApiResponse } from "../../../../utils/commonMethods";
import {
  wordCloudNoDataMessage,
  apiErrorMessage,
} from "../../../../assets/errorMessageConstants";
import "./index.scss";
import { useParentSize } from '@visx/responsive';
import { Loader } from "@gitlab-rtsensing/component-library";
import { CancelTokenSource } from "axios";

const POP_OVER_TEXT = "The wordcloud analyzes articles ingested over the past 30, 60, or 90 days across all publishers currently available in Sensing. it is updated weekly to ensure timely insights."
type WordDT = {
  keyword: string;
  detail: string;
  topic_frequency: number;
  topic_strength: number;
  sentiment_score: number;
  article_ids: string[];
  cluster_number: number;
  cluster_strength: number;
  number_of_articles: number;
}

const wordCategories = [
  { name: "Positive", color: "#0063C3", lightColor: "#E5EFF9"},
  { name: "Neutral",  color: "#7E7E7E", lightColor: "#F3F3F3"},
  { name: "Negative", color: "#FF6720", lightColor: "#FFF0E9"},
]

export function NewsWordCloud() {
  const [WordCloudData, setWordCloudData] = useState<any[]>([]);
  const [wordCloudErrorMessage, setWordCloudErrorMessage] = useState("");
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [selectedNumberOfDays, setSelectedNumberOfDays] = useState(30);
  const { parentRef: wordCloudRef, width: wordCloudWidth } = useParentSize({ debounceTime: 150 });
  let cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const getCategory = (sentiment: number): string => {
    if (sentiment > 0.33) return "Positive";
    if (sentiment < -0.33) return "Negative";

    return "Neutral"
  }

  const fetchWordCloudData = async () => {
    setIsDataLoading(true);
    setWordCloudErrorMessage("");
    try {
      const res = await lastApiResponse("get", "news-cloud", cancelTokenSource, {}, `?number_of_days=${selectedNumberOfDays}`);
      if (res.data.status === "SUCCESS") {
        const transformAPIResponseToWords = (arr: Array<WordDT> | null) => {
          return arr?.map((item: WordDT) => ({
            "text": item.keyword,
            "value": item.topic_frequency,
            "category": getCategory(item.sentiment_score),
            "detail": item.detail
          })) || []
        }
        
        setWordCloudData(transformAPIResponseToWords(res.data.data[0].word_details))

      } else {
        setWordCloudData([]);
        setWordCloudErrorMessage(wordCloudNoDataMessage);
      }
      setIsDataLoading(false);
    } catch (error) {
      setWordCloudData([]);
      setIsDataLoading(false);
      setWordCloudErrorMessage(apiErrorMessage);
    }
  };

  useEffect(() => {
    fetchWordCloudData();
  }, [selectedNumberOfDays]);

  // Mobile specific scaling of font-sizes
  const getWordcloudFontSize = (width: number): [number, number] => {
    if(width < 576) return [10, 18];
    if(width < 768) return [12, 24];
    return [15, 32];
  }
  
  const displayWordCloud = useMemo(() => {
    if (isDataLoading || WordCloudData.length === 0) return <div className="loading-container"><Loader /></div>;
    if (wordCloudErrorMessage) return <div className="loading-container">{wordCloudErrorMessage}</div>;
    return (
        <div id="word-cloud" className="ext-wordcloud-wrapper">
          <WordCloud
            fontSizes={getWordcloudFontSize(wordCloudWidth)}
            fontFamily="Inter"
            width={wordCloudWidth} // Scaling w/ bootstrap grid
            height={250}
            categories={wordCategories}
            showLegend={true}
            words={WordCloudData}
            spiralType="archimedean"
            padding={2}
            deterministic={true}
            onPopoverContent={(word: any) => <div>{word.detail}</div>}
          />
        </div>
      )
    }, [WordCloudData, wordCloudWidth, isDataLoading, wordCloudErrorMessage]);

  return (
    <Card 
      title={{
        title: 'Trending News Topics',
        popoverContent: POP_OVER_TEXT,
        popoverIconColor: 'secondary',
      }}
      className='ext-news-cloud'
    >
      <div ref={wordCloudRef} style={{ height: '100%' }}>
        <Tabs
          onTabClick={activeKey => setSelectedNumberOfDays(Number(activeKey))}
          items={[
            {
              children: displayWordCloud,
              key: '30',
              label: 'Last 30 Days'
            },
            {
              children: displayWordCloud,
              key: '60',
              label: 'Last 60 Days'
            },
            {
              children: displayWordCloud,
              key: '90',
              label: 'Last 90 Days'
            }
          ]}
        />
      </div>
    </Card>
  );
};
